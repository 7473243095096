<template>
    <breadcrumb btnText="新建" @btn-click="$router.push('home-carousel/create')" />
    <div class="recommend-carousel">
        <!-- <h5 class="title-warning">首页轮播图最多6张</h5> -->
        <div class="list-container">
            <table class="table">
                <thead>
                    <td>id</td>
                    <td>图片</td>
                    <td>标题</td>
                    <td>跳转链接</td>
                    <td>更新时间</td>
                    <td>操作</td>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index">
                        <td>{{ item.id }}</td>
                        <td>
                            <img class="thumb" :src="item.img" />
                        </td>
                        <td>{{ item.title }}</td>
                        <td>{{ item.url }}</td>
                        <td>{{ item.updated_at }}</td>
                        <td>
                            <div class="operation">
                                <n-button class="btn" 
                                    type="primary" 
                                    @click="$router.push(`home-carousel/edit/${item.id}`)"
                                >
                                    <edit theme="outline" size="16" fill="#fff"/>
                                </n-button>
                                <n-popconfirm 
                                    position="bottom right"
                                    content="确定永久删除这条轮播吗？"
                                    @confirm="handlerDelete(item.id, index)"
                                >
                                    <n-button class="btn" type="danger">
                                        <delete theme="outline" size="16" fill="#fff"/>
                                    </n-button>
                                </n-popconfirm>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <n-notification 
        v-model:show="notification.show"
        :content="notification.content"
    >
        <template v-slot:icon>
            <attention v-if="notification.state === 'error'" theme="outline" size="24" fill="#ff2121"/>
            <check-one v-else theme="outline" size="24" fill="#00c12b"/>
        </template>
    </n-notification>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';
export default {
    name: 'RecommendCarousel',
    components: {
        Breadcrumb, Edit, Delete, Attention, CheckOne 
    },
    data(){
        return {
            list: {},
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        getData(){
            this.$api.xrHomeCarousels().then(res => {
                console.log(res)
                this.list = res.data;
            })
        },
        handlerDelete(id, index){
            this.$api.xrDestroyHomeCarousel(id).then(res => {
                console.log(res)
                if(res.msg === 'success'){
                    this.list.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.recommend-carousel{
    @extend .flex-column;
    margin: 0 15px;
    border-radius: $radius;
    background-color: $white;
    box-shadow: $shadow-default;
    .title-warning{
        margin: 0;
        padding: 15px;
        font-size: 14px;
        font-weight: normal;
        color: $warning;
        border-bottom: 1px solid $page-color;
    }
    .list-container{
        padding: 10px;
        .table{
            td .thumb{
                width: 192px;
                height: 108px;
            }
        }
    }
}
</style>